document.addEventListener('DOMContentLoaded', () => {
  // Initialize expandable text blocks
  const initExpandableText = () => {
    const containers = document.querySelectorAll('.js-expandable-text');
    const LINE_HEIGHT = 1.5;  // em
    const LINES_TO_SHOW = 3;
    const MAX_HEIGHT = `${LINE_HEIGHT * LINES_TO_SHOW}em`;

    containers.forEach(container => {
      const content = container.querySelector('.js-expandable-text-content');
      const button = container.querySelector('.js-expandable-text-button');
      const buttonText = button.querySelector('.expandable-text__button-text');
      const buttonSr = button.querySelector('.js-expandable-text-button-sr');

      // Set initial max-height
      content.style.maxHeight = MAX_HEIGHT;

      // Calculate if we need the expand/collapse functionality
      const isContentTruncated = () => {
        const tempMaxHeight = content.style.maxHeight;
        content.style.maxHeight = 'none';
        const fullHeight = content.scrollHeight;
        content.style.maxHeight = tempMaxHeight;
        return fullHeight > parseFloat(MAX_HEIGHT) * 16; // convert em to px (assuming 16px font-size)
      };

      // Only show button if content needs truncation
      if (!isContentTruncated()) {
        button.style.display = 'none';
        content.style.maxHeight = 'none';
        return;
      }

      // Handle button click
      button.addEventListener('click', () => {
        const isExpanded = button.getAttribute('aria-expanded') === 'true';
        
        if (isExpanded) {
          // Collapse
          content.style.maxHeight = MAX_HEIGHT;
          button.setAttribute('aria-expanded', 'false');
          buttonText.textContent = 'Vis mer';
          buttonSr.textContent = 'Vis mer av teksten';
        } else {
          // Expand
          content.style.maxHeight = content.scrollHeight + 'px';
          button.setAttribute('aria-expanded', 'true');
          buttonText.textContent = 'Vis mindre';
          buttonSr.textContent = 'Vis mindre av teksten';
        }
      });

      // Initial state
      button.setAttribute('aria-expanded', 'false');
      buttonText.textContent = 'Vis mer';
      buttonSr.textContent = 'Vis mer av teksten';
    });
  };

  // Initialize all collapsible components
  initExpandableText();
}); 